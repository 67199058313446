import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Grid,
  Container,
  FormLabel,
  TextField,
} from "@material-ui/core";
import { DatabaseContext } from "../../contexts/DatabaseContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

function SchoolStudentLookUp() {
  const { IDCaptureAPI } = useContext(DatabaseContext);
  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  const [MessageModal, setMessageModal] = useState(false);
  const [loading, setLoading] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const back = async () => {
    navigate("/selectschool", { replace: true });
  };

  const next = async () => {
    var isStudentFound;
    if (values.FirstName && values.SurName && values.DateOfBirth) {
      setLoading(true);
      sessionStorage.setItem("firstName", values.FirstName);
      sessionStorage.setItem("middleName", values.MiddleName);
      sessionStorage.setItem("surname", values.SurName);
      sessionStorage.setItem("DateOfBirth", values.DateOfBirth);

      try {
        const res = await Axios.get(
          `${IDCaptureAPI}/api/sync/wonde/getStudentDetails/?siteName=${sessionStorage.siteName}&firstName=${values.FirstName}&lastName=${values.SurName}&dateOfBirth=${values.DateOfBirth}`
        );

        isStudentFound = res.data.success;
        if (isStudentFound == true) {
          var photoUpdated = await Axios.put(
            `${IDCaptureAPI}/api/sync/wonde/updateStudentPhoto/?siteCode=${sessionStorage.siteCode}&studentID=${res.data.data.mis_id}`
          );

          console.log(photoUpdated);
          if (photoUpdated.data.success == true) {
            enqueueSnackbar("Student Photo Updated", {
              variant: "success",
              autoHideDuration: 6000,
            });
          } else {
            enqueueSnackbar("Unable to Update Student Photo", {
              variant: "error",
              autoHideDuration: 6000,
            });
          }
        }
        setLoading(false);
        navigate("/schoolstudentaddressverification", { replace: true });
      } catch (err) {
        isStudentFound = err.response.data.success;
        setMessageModal(true);
        setLoading(false);
        enqueueSnackbar(err.response.data.error, {
          variant: "error",
          autoHideDuration: 6000,
        });
      }
    } else {
      handleSubmit();
    }
  };

  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setValues,
    setDate,
    handleSubmit,
    resetForm,
    onChange,
  } = useFormik({
    initialValues: {
      FirstName: "",
      MiddleName: "",
      SurName: "",
      DateOfBirth: "",
    },
    validationSchema: Yup.object({
      //ID: Yup.number().integer(),
      FirstName: Yup.string().required("Please Enter your First Name"),
      MiddleName: Yup.string().nullable(),
      SurName: Yup.string().required("Please Enter your Surname"),
      DateOfBirth: Yup.string().required("Please Enter your date of birth"),
    }),
  });

  useEffect(() => {
    setTimeout(() => {
      sessionStorage.removeItem("siteName");
      sessionStorage.removeItem("siteCode");
      sessionStorage.removeItem("siteOrderTypeID");
      window.location.href = "/buyonline";
    }, 7200000);
  }, []);

  return (
    <div className="App">
      <div className="App-content">
        <h style={window.innerWidth > 775 ? styleHeader : styleHeader1}>
          Student Details
        </h>
        <br />
        <form>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}></Grid>
            <p style={window.innerWidth > 775 ? listStyle : listStyle1}>
              Please enter the students details below, please ensure that the
              details entered are as per their school student enrollment. These
              details will be used to verify the student with your previously
              selected school.
            </p>
            {/* </Grid> */}
            <br />
            {/* <Container> */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={
                window.innerWidth > 775
                  ? { marginLeft: "35%", marginRight: "35%" }
                  : {
                      marginLeft: "10%",
                      marginRight: "10%",
                    }
              }
            >
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="FirstName"
                  name="FirstName"
                  label="First Name"
                  value={values.FirstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.FirstName && errors.FirstName}
                  helperText={
                    errors.FirstName && touched.FirstName && errors.FirstName
                  }
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  required
                  style={{
                    width: "100%",
                  }}
                ></TextField>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                padding={{ paddingTop: "20px" }}
              >
                <TextField
                  id="MiddleName"
                  name="MiddleName"
                  label="Middle Name"
                  value={values.MiddleName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.MiddleName && errors.MiddleName}
                  helperText={
                    errors.MiddleName && touched.MiddleName && errors.MiddleName
                  }
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  style={{
                    width: "100%",
                  }}
                ></TextField>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                padding={{ paddingTop: "20px" }}
              >
                <TextField
                  id="SurName"
                  name="SurName"
                  label="Surname"
                  value={values.SurName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.SurName && errors.SurName}
                  helperText={
                    errors.SurName && touched.SurName && errors.SurName
                  }
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  required
                  style={{
                    width: "100%",
                  }}
                ></TextField>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                padding={{ paddingTop: "20px" }}
              >
                <TextField
                  name="DateOfBirth"
                  type="date"
                  label="Date Of Birth"
                  value={values.DateOfBirth}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth={true}
                  error={touched.DateOfBirth && errors.DateOfBirth}
                  helperText={
                    errors.DateOfBirth &&
                    touched.DateOfBirth &&
                    errors.DateOfBirth
                  }
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
          <br />
          {loading > 0 ? (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4}></Grid>
              <Grid item xs={12} sm={12} md={12}>
                <p style={messageStyle}>Loading Student Data, Please Wait...</p>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Button
            style={
              window.innerWidth > 775
                ? {
                    backgroundColor: "#D3212D",
                    color: "#ffffff",
                    marginLeft: "34%",
                    fontSize: 20,
                    width: "15%",
                  }
                : {
                    backgroundColor: "#D3212D",
                    color: "#ffffff",
                    marginLeft: "30%",
                    fontSize: "110%",
                  }
            }
            onClick={back}
          >
            Back
          </Button>

          <Button
            style={
              window.innerWidth > 775
                ? {
                    backgroundColor: "green",
                    color: "#ffffff",
                    marginLeft: "2%",
                    fontSize: 20,
                    width: "15%",
                  }
                : {
                    backgroundColor: "green",
                    color: "#ffffff",
                    marginLeft: "2%",
                    fontSize: "110%",
                  }
            }
            onClick={next}
          >
            Continue
          </Button>
          {/* </Container> */}
        </form>
        <Grid style={{ marginTop: "2%" }}></Grid>
      </div>
    </div>
  );
}

const styleHeader = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const styleHeader1 = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const listStyle = {
  fontSize: 18,
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "30%",
  paddingRight: "30%",
};

const listStyle1 = {
  fontSize: "140%",
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const messageStyle = {
  fontSize: 18,
  color: "#ffffff",
  textAlign: "centre",
  alignItems: "center",
  textAlign: "center",
};
export default SchoolStudentLookUp;
