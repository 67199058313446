import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Container,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogActions,
  FormLabel,
} from "@material-ui/core";
import Axios from "axios";
import { DatabaseContext } from "../../contexts/DatabaseContext";
import StandardCard from "../../images/Cards/standard_Card_Sm.JPG";
import CustomisedCard from "../../images/Cards/Personalised_Card_Sm.JPG";
import { ConfirmationNumber, Label } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function ConfirmDetails() {
  const [checked, setChecked] = React.useState(false);
  const { IDCaptureAPI } = useContext(DatabaseContext);
  const [MessageModal, setMessageModal] = useState(false);
  const [isWondeSchool, setIsWondeSchool] = useState(false);
  const [isSchoolDelivery, setIsSchoolDelivery] = useState(false);
  const [cardDesign, setCardDesign] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const navigate = useNavigate();
  const [isMobileDevice, setIsMobileDevice] = useState();

  const back = async () => {
    if (isWondeSchool == true) {
      sessionStorage.removeItem("phone");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("parent");
      sessionStorage.removeItem("street");
      sessionStorage.removeItem("suburb");
      sessionStorage.removeItem("state");
      sessionStorage.removeItem("postcode");
      sessionStorage.removeItem("ResStreet");
      sessionStorage.removeItem("ResSuburb");
      sessionStorage.removeItem("ResState");
      sessionStorage.removeItem("ResPostcode");
      window.location.href = "/parentidcapturestep4";
    } else {
      sessionStorage.removeItem("phone");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("parent");
      sessionStorage.removeItem("street");
      sessionStorage.removeItem("suburb");
      sessionStorage.removeItem("state");
      sessionStorage.removeItem("postcode");
      sessionStorage.removeItem("ResStreet");
      sessionStorage.removeItem("ResSuburb");
      sessionStorage.removeItem("ResState");
      sessionStorage.removeItem("ResPostcode");
      navigate("/studentcontactinformation", { replace: true });
    }
  };

  const getWondeSchool = async () => {
    var siteName = sessionStorage.getItem("siteName");

    if (siteName) {
      const res = await Axios.get(
        `${IDCaptureAPI}/api/smartrider/search/?filterInput=${siteName}`
      );
      var schoolDetails = res.data.data[0];

      setIsWondeSchool(schoolDetails.wondeAllowed > 0 ? true : false);
    }
  };

  const getSchoolDelivery = (async) => {
    var value = sessionStorage.getItem("siteOrderTypeID");
    setIsSchoolDelivery(value == 2 ? true : false);
  };

  const cancel = async () => {
    sessionStorage.removeItem("firstName");
    sessionStorage.removeItem("middleName");
    sessionStorage.removeItem("surname");
    sessionStorage.removeItem("DateOfBirth");
    sessionStorage.removeItem("siteName");
    sessionStorage.removeItem("phone");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("parent");
    sessionStorage.removeItem("street");
    sessionStorage.removeItem("suburb");
    sessionStorage.removeItem("state");
    sessionStorage.removeItem("postcode");
    sessionStorage.removeItem("ResStreet");
    sessionStorage.removeItem("ResSuburb");
    sessionStorage.removeItem("ResState");
    sessionStorage.removeItem("ResPostcode");
    sessionStorage.removeItem("ExternalID");
    sessionStorage.removeItem("YearGroup");
    window.location.href = "/buyonline";
    navigate("/buyonline", { replace: true });
  };

  const next = async () => {
    var a = [];
    var orderValidation = false;
    var OVMessage = "";
    var siteName = sessionStorage.getItem("siteName");

    if (siteName) {
      const res = await Axios.get(
        `${IDCaptureAPI}/api/smartrider/search/?filterInput=${siteName}`
      );
      var schoolDetails = res.data.data[0];

      var isWondeSchool = schoolDetails.wondeAllowed > 0 ? true : false;
    }
    a.push(PatronOrder);
    try {
      const res = await Axios.post(
        `${IDCaptureAPI}/api/parentOrders/validateOrder?isWondeOrder=${isWondeSchool}`,
        a
      );

      orderValidation = res.data.success;
      OVMessage = res.data.data;
    } catch (err) {
      orderValidation = err.response.data.success;
      OVMessage = err.response.data.data;
    }

    if (checked === true && orderValidation !== false) {
      navigate("/accessories", { replace: true });
    } else {
      if (checked === false) {
        setMessageModal(true);
      }
      if (orderValidation === false) {
        window.alert(OVMessage);
      }
    }
  };

  const PatronOrder = JSON.stringify({
    ParentOrderID: "0",
    PatronID: "0",
    OrderID: 0,
    TransactionID: 0,
    ExternalID: `${sessionStorage.getItem("ExternalID")}`,
    FirstName: `${sessionStorage.getItem("firstName")}`,
    Lastname: `${sessionStorage.getItem("surname")}`,
    DOB: `${sessionStorage.getItem("DateOfBirth")}`,
    YearGroup: `${sessionStorage.getItem("YearGroup")}`,
    SiteCode: `${sessionStorage.getItem("siteCode")}`,
    ParentName: `${sessionStorage.getItem("parent")}`,
    ParentEmail: `${sessionStorage.getItem("email")}`,
    ParentPhone: `${sessionStorage.getItem("phone")}`,
    Address: `${sessionStorage.getItem("ResStreet")}`,
    Suburb: `${sessionStorage.getItem("ResSuburb")}`,
    State: `${sessionStorage.getItem("ResState")}`,
    Postcode: `${sessionStorage.getItem("ResPostcode")}`,
    DeliveryAddress: `${sessionStorage.getItem("street")}`,
    DeliverySuburb: `${sessionStorage.getItem("suburb")}`,
    DeliveryState: `${sessionStorage.getItem("state")}`,
    DeliveryPostcode: `${sessionStorage.getItem("postcode")}`,
    DelToPatronAddress: 1,
  });

  useEffect(() => {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    setIsMobileDevice(regexp.test(details));

    getWondeSchool();
    getSchoolDelivery();
    setStudentData(sessionStorage);
    (async () => {
      const res = await Axios.get(
        `${IDCaptureAPI}/api/smartrider/setsearch/?filterInput=${sessionStorage.siteName}`
      );
      setCardDesign(res.data.data[0].schoolCard);
    })();
    setTimeout(() => {
      sessionStorage.removeItem("siteName");
      sessionStorage.removeItem("siteCode");
      sessionStorage.removeItem("siteOrderTypeID");
      sessionStorage.removeItem("firstName");
      sessionStorage.removeItem("middleName");
      sessionStorage.removeItem("surname");
      sessionStorage.removeItem("DateOfBirth");
      sessionStorage.removeItem("phone");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("parent");
      sessionStorage.removeItem("ResStreet");
      sessionStorage.removeItem("ResSuburb");
      sessionStorage.removeItem("ResState");
      sessionStorage.removeItem("ResPostcode");
      sessionStorage.removeItem("street");
      sessionStorage.removeItem("suburb");
      sessionStorage.removeItem("state");
      sessionStorage.removeItem("postcode");
      sessionStorage.removeItem("cartItems");
      sessionStorage.removeItem("ExternalID");
      window.location.href = "/buyonline";
    }, 7200000);
  }, [isMobileDevice]);

  return (
    <div className="App">
      <div className="App-content">
        <h
          style={
            (window.innerWidth > 775 ? styleHeader : styleHeader1,
            {
              fontSize: isMobileDevice ? "20px" : "30px",
              fontWeight: "Bold",
            })
          }
        >
          Confirm Details
        </h>
        <br />
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={6} md={4} lg={4}>
              <Grid
                container
                spacing={2}
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  marginRight: "20%",
                  width: "99%",
                }}
              >
                <Grid item xs={12} md={12} lg={12}>
                  <h
                    style={
                      (styleColumnHeader,
                      {
                        fontSize: isMobileDevice ? "16px" : "20px",
                        fontWeight: "Bold",
                      })
                    }
                  >
                    Student Details
                  </h>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    textAlign: "left",
                    borderColor: "white",
                    border: "1px solid gray",
                    borderRadius: "5px",
                    marginBottom: "20px",
                    marginTop: "10px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "normal",
                      color: "gray",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    First Name:{" "}
                  </FormLabel>
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    {studentData.firstName}
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    textAlign: "left",
                    borderColor: "white",
                    border: "1px solid gray",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "normal",
                      color: "gray",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    Middle Name:{" "}
                  </FormLabel>
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    {studentData.middleName}
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    textAlign: "left",
                    borderColor: "white",
                    border: "1px solid gray",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "normal",
                      color: "gray",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    Surname:{" "}
                  </FormLabel>
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    {studentData.surname}
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    textAlign: "left",
                    borderColor: "white",
                    border: "1px solid gray",
                    borderRadius: "5px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "normal",
                      color: "gray",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    Date of Birth:{" "}
                  </FormLabel>
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    {studentData.DateOfBirth}
                  </FormLabel>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={4} lg={4}>
              <Grid
                container
                spacing={2}
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  marginRight: "20%",
                  width: "99%",
                }}
              >
                <Grid item xs={12} md={12} lg={12}>
                  <h
                    style={
                      (styleColumnHeader,
                      {
                        fontSize: isMobileDevice ? "16px" : "20px",
                        fontWeight: "Bold",
                      })
                    }
                  >
                    Personal Details
                  </h>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    textAlign: "left",
                    borderColor: "white",
                    border: "1px solid gray",
                    borderRadius: "5px",
                    marginBottom: "20px",
                    marginTop: "10px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "normal",
                      color: "gray",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    Phone:{" "}
                  </FormLabel>
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    {studentData.phone}
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    textAlign: "left",
                    borderColor: "white",
                    border: "1px solid gray",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "normal",
                      color: "gray",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    Email:{" "}
                  </FormLabel>
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    {studentData.email}
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    textAlign: "left",
                    borderColor: "white",
                    border: "1px solid gray",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "normal",
                      color: "gray",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    Parent/Guardian:{" "}
                  </FormLabel>
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    {studentData.parent}
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    textAlign: "left",
                    borderColor: "white",
                    border: "1px solid gray",
                    borderRadius: "5px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "normal",
                      color: "gray",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    School Name:{" "}
                  </FormLabel>
                  <FormLabel
                    style={{
                      fontSize: isMobileDevice ? "14px" : "20px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    {studentData.siteName}
                  </FormLabel>
                </Grid>
              </Grid>
            </Grid>

            {!isSchoolDelivery ? (
              <Grid item xs={6} md={4} lg={4}>
                <Grid
                  container
                  spacing={2}
                  style={{
                    marginLeft: "10px",
                    marginTop: "10px",
                    marginRight: "20%",
                    width: "99%",
                  }}
                >
                  <Grid item xs={12} md={12} lg={12}>
                    <h
                      style={
                        (styleColumnHeader,
                        {
                          fontSize: isMobileDevice ? "16px" : "20px",
                          fontWeight: "Bold",
                        })
                      }
                    >
                      Card Delivery Address
                    </h>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                      textAlign: "left",
                      borderColor: "white",
                      border: "1px solid gray",
                      borderRadius: "5px",
                      marginBottom: "20px",
                      marginTop: "10px",
                    }}
                  >
                    <FormLabel
                      style={{
                        fontSize: isMobileDevice ? "14px" : "20px",
                        fontWeight: "normal",
                        color: "gray",
                        width: "100%",
                        marginTop: "20px",
                      }}
                    >
                      Street:{" "}
                    </FormLabel>
                    <FormLabel
                      style={{
                        fontSize: isMobileDevice ? "14px" : "20px",
                        fontWeight: "bold",
                        color: "#ffffff",
                        width: "100%",
                        marginTop: "20px",
                      }}
                    >
                      {studentData.street !== undefined
                        ? studentData.street
                        : studentData.ResStreet}
                    </FormLabel>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                      textAlign: "left",
                      borderColor: "white",
                      border: "1px solid gray",
                      borderRadius: "5px",
                      marginBottom: "20px",
                    }}
                  >
                    <FormLabel
                      style={{
                        fontSize: isMobileDevice ? "14px" : "20px",
                        fontWeight: "normal",
                        color: "gray",
                        width: "100%",
                        marginTop: "20px",
                      }}
                    >
                      Suburb:{" "}
                    </FormLabel>
                    <FormLabel
                      style={{
                        fontSize: isMobileDevice ? "14px" : "20px",
                        fontWeight: "bold",
                        color: "#ffffff",
                        width: "100%",
                        marginTop: "20px",
                      }}
                    >
                      {studentData.suburb !== undefined
                        ? studentData.suburb
                        : studentData.ResSuburb}
                    </FormLabel>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                      textAlign: "left",
                      borderColor: "white",
                      border: "1px solid gray",
                      borderRadius: "5px",
                      marginBottom: "20px",
                    }}
                  >
                    <FormLabel
                      style={{
                        fontSize: isMobileDevice ? "14px" : "20px",
                        fontWeight: "normal",
                        color: "gray",
                        width: "100%",
                        marginTop: "20px",
                      }}
                    >
                      State:{" "}
                    </FormLabel>
                    <FormLabel
                      style={{
                        fontSize: isMobileDevice ? "14px" : "20px",
                        fontWeight: "bold",
                        color: "#ffffff",
                        width: "100%",
                        marginTop: "20px",
                      }}
                    >
                      {studentData.state !== undefined
                        ? studentData.state
                        : studentData.ResState}
                    </FormLabel>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                      textAlign: "left",
                      borderColor: "white",
                      border: "1px solid gray",
                      borderRadius: "5px",
                    }}
                  >
                    <FormLabel
                      style={{
                        fontSize: isMobileDevice ? "14px" : "20px",
                        fontWeight: "normal",
                        color: "gray",
                        width: "100%",
                        marginTop: "20px",
                      }}
                    >
                      Postcode:{" "}
                    </FormLabel>
                    <FormLabel
                      style={{
                        fontSize: isMobileDevice ? "14px" : "20px",
                        fontWeight: "bold",
                        color: "#ffffff",
                        width: "100%",
                        marginTop: "20px",
                      }}
                    >
                      {studentData.postcode !== undefined
                        ? studentData.postcode
                        : studentData.ResPostcode}
                    </FormLabel>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid item xs={6} md={4} lg={4}></Grid>
              </>
            )}

            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                {isSchoolDelivery ? (
                  <h1 style={{ color: "yellow", fontSize: "160%" }}>
                    {" "}
                    Your Smartrider will be delivered to{" "}
                    {sessionStorage.siteName} for collection
                  </h1>
                ) : (
                  <></>
                )}
                <br />
                <Grid item xs={12} md={6}>
                  <h
                    style={{
                      color: "#FFA500",
                      fontSize: isMobileDevice ? "16px" : "20px",
                      fontWeight: "Bold",
                      paddingLeft: "10px",
                    }}
                  >
                    Please Note:
                  </h>
                </Grid>
                <Grid item xs={12} md={6}></Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={2}></Grid>
                  <Grid item xs={12} md={10}>
                    <p
                      style={
                        {
                          fontSize: isMobileDevice ? "16px" : "20px",
                          fontWeight: "Bold",
                        }
                        //   window.innerWidth > 775
                        //     ? { fontSize: 16, marginleft: "40px" }
                        //     : {
                        //         marginLeft: "5%",
                        //         marginRight: "5%",
                        //         fontSize: "140%",
                        //       }
                      }
                    >
                      Please ensure that all the details above are correct, if
                      any of the information above is incorrect and the
                      Smartrider is supplied then you will have to purchase
                      another Smartrider (at your cost) to correct the mistake.
                    </p>
                    <p
                      style={
                        {
                          fontSize: isMobileDevice ? "16px" : "20px",
                          fontWeight: "Normal",
                        }
                        // window.innerWidth > 775
                        //   ? { fontSize: 16, marginleft: "40px" }
                        //   : {
                        //       marginLeft: "5%",
                        //       marginRight: "5%",
                        //       fontSize: "140%",
                        //     }
                      }
                    >
                      Non School Designed Smartrider cards do not include school
                      details and/or a student photo. If you require a school
                      designed card this can only be ordered by the student's
                      school (if applicable).
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4}>
                {cardDesign === true ? (
                  <img
                    src={CustomisedCard}
                    alt="smartrider_image"
                    style={{ marginLeft: "2%" }}
                  />
                ) : (
                  <img
                    src={StandardCard}
                    alt="smartrider_image"
                    style={{ marginLeft: "2%" }}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <FormControlLabel
                  style={styleCheckBoxText}
                  label="I confirm the above details are correct and understand that I will receive a Smartrider card with the design similar the example shown. "
                  control={
                    <Checkbox
                      style={styleCheckBox}
                      name="checked"
                      defaultChecked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <br />
          <br />
          <Button
            style={{
              backgroundColor: "#ff3232",
              color: "#ffffff",
              fontSize: isMobileDevice ? 16 : 20,
              width: isMobileDevice ? "100%" : "20%",
            }}
            onClick={cancel}
          >
            Cancel Order
          </Button>
          {isMobileDevice ? <br /> : ""}
          {isMobileDevice ? <br /> : ""}
          <Button
            style={{
              backgroundColor: "#ffbf00",
              color: "#ffffff",
              fontSize: isMobileDevice ? 16 : 20,
              width: isMobileDevice ? "100%" : "20%",
            }}
            onClick={back}
          >
            Re-enter details
          </Button>
          {isMobileDevice ? <br /> : ""}
          {isMobileDevice ? <br /> : ""}
          <Button
            style={{
              backgroundColor: "green",
              color: "#ffffff",
              fontSize: isMobileDevice ? 16 : 20,
              width: isMobileDevice ? "100%" : "20%",
            }}
            onClick={next}
          >
            Continue
          </Button>

          <Dialog open={MessageModal} onClose={() => setMessageModal(false)}>
            <DialogContent>
              Please confirm your child's details and that you
              <br />
              understand the card design that you will receive
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setMessageModal(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Grid style={{ marginTop: "3%" }}></Grid>
        </Container>
      </div>
    </div>
  );
}

const styleHeader = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const styleHeader1 = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const styleCheckBox = {
  fontSize: 16,
  color: "#ffffff",
  textAlign: "left",
  width: "20px",
  marginRight: "20px",
};

const styleCheckBoxText = {
  fontSize: 16,
  color: "#ffffff",
  textAlign: "left",
  paddingTop: "7%",
  marginLeft: "2%",
};

const styleColumnHeader = {
  color: "#FFFFFF",
};

export default ConfirmDetails;
