import React, { useState, useEffect, useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import Axios from "axios";
import { DatabaseContext } from "../../contexts/DatabaseContext";

function PurchaseComplete() {
  const [purchaseOK, setPurchaseOK] = useState(false);
  const { IDCaptureAPI } = useContext(DatabaseContext);
  const [declineMessage, showDeclineMessage] = useState(false);
  const [WarnMessage, setWarnMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const onlineStore = async () => {
    window.open("https://www.monitorwa.com.au/product-category/accessories/");
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      showDeclineMessage(false);
      try {
        const res = await Axios.get(
          `${IDCaptureAPI}/api/parentOrders/paymentResponse/?AccessCode=${sessionStorage.getItem(
            "AccessCode"
          )}`
        ).catch((err) => {
          console.log(err.code);
        });

        var iCount = 0;

        do {
          try {
            const res = await Axios.get(
              `${IDCaptureAPI}/api/parentOrders/paymentResponse/?AccessCode=${sessionStorage.getItem(
                "AccessCode"
              )}`
            ).catch((err) => {
              console.log(err.code);
            });
          } catch (err) {
            console.log(err);
          }
          iCount++;
        } while (res.data.data.TransID < 1 && iCount < 5);

        if (res.data.data.TransID < 1) {
          setWarnMessage(true);
          const sendEmail = await Axios.post(
            `${IDCaptureAPI}/api/parentOrders/sendemail/`,
            [
              `Warning Messsage No Transaction ID Received-
              Parent Email=
              ${sessionStorage.getItem("email")},
              Parent Name =
              ${sessionStorage.getItem("parent")},
              AccessCode = 
              ${sessionStorage.getItem("AccessCode")}`,
            ]
          );
        }

        if (res.data.data.TransID > 1) {
          const ft = await Axios.put(
            `${IDCaptureAPI}/api/parentOrders/finaliseTransaction/`,
            {
              AuthCode: res.data.data.AuthCode,
              TransID: res.data.data.TransID,
              AccessCode: `${sessionStorage.getItem("AccessCode")}`,
            }
          ).catch((err) => {
            console.log(err);
          });
          setPurchaseOK(true);
          sessionStorage.removeItem("AccessCode");
        }
      } catch {
        showDeclineMessage(true);

        const sendEmail = await Axios.post(
          `${IDCaptureAPI}/api/parentOrders/sendemail/`,
          [
            `Failed to Finalise Transaction - Parent Email=
            ${sessionStorage.getItem("email")}, 
            Parent Name =
            ${sessionStorage.getItem("parent")},
            AccessCode = 
              ${sessionStorage.getItem("AccessCode")}`,
          ]
        ).catch((err) => {
          console.log(err);
        });
      }
      setLoading(false);
    })();

    sessionStorage.removeItem("firstName");
    sessionStorage.removeItem("middleName");
    sessionStorage.removeItem("surname");
    sessionStorage.removeItem("DateOfBirth");
    sessionStorage.removeItem("siteName");
    sessionStorage.removeItem("phone");
    sessionStorage.removeItem("ResStreet");
    sessionStorage.removeItem("ResSuburb");
    sessionStorage.removeItem("ResState");
    sessionStorage.removeItem("ResPostcode");
    sessionStorage.removeItem("street");
    sessionStorage.removeItem("suburb");
    sessionStorage.removeItem("state");
    sessionStorage.removeItem("postcode");
    sessionStorage.removeItem("cartItems");
    sessionStorage.removeItem("ExternalID");
    sessionStorage.removeItem("YearGroup");

    setTimeout(() => {
      sessionStorage.removeItem("siteCode");
      sessionStorage.removeItem("siteOrderTypeID");
      window.location.href = "/buyonline";
    }, 7200000);
  }, [IDCaptureAPI]);

  return (
    <div className="App">
      <div className="App-content">
        <h style={window.innerWidth > 775 ? styleHeader : styleHeader1}>
          {console.log(declineMessage)}
          {console.log(purchaseOK)}
          {console.log(WarnMessage)}
          {declineMessage == false && purchaseOK == true && WarnMessage == false
            ? `Smartrider Purchase Completed`
            : loading
            ? `Smartrider Loading Purchase`
            : `Smartrider Purchase Failed`}
        </h>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={3}></Grid>
          <Grid item xs={12} sm={12} md={6}>
            {purchaseOK ? (
              <p style={window.innerWidth > 775 ? listStyleY : listStyleY1}>
                The purchase of your smartrider is now completed, your
                smartrider will be printed and dispatched to your school or the
                delivery address (conditions apply) within the next 2 business
                days.
                <p style={window.innerWidth > 775 ? listStyleY12 : listStyleY2}>
                  You may close this all Monitor WA window(s)/tabs.
                </p>
              </p>
            ) : (
              <></>
              // <p style={window.innerWidth > 775 ? listStyle : listStyle1}>
              //   Your purchased is being finalised, please wait
              // </p>
            )}

            {WarnMessage ? (
              <p style={window.innerWidth > 775 ? listStyleY : listStyleY1}>
                Unable to process your order at this time, Monitor WA will
                contact you by the next business day in regards to this order.
                <br /> <br />
                <b>Please Note:</b> You don't need to re-order at this time
                <p style={window.innerWidth > 775 ? listStyleY12 : listStyleY2}>
                  You may close all Monitor WA window(s)/tabs.
                </p>
              </p>
            ) : (
              <></>
              // <p style={window.innerWidth > 775 ? listStyle : listStyle1}>
              //   Your purchased is being finalised, please wait
              // </p>
            )}

            {declineMessage == false &&
            purchaseOK == false &&
            WarnMessage == false ? (
              <p style={window.innerWidth > 775 ? listStyle : listStyle1}>
                Your purchased is being finalised, please wait
              </p>
            ) : (
              <></>
            )}

            {declineMessage == true &&
            sessionStorage.getItem("AccessCode") !== null ? (
              <p style={window.innerWidth > 775 ? listStyleY12 : listStyleY2}>
                Transaction has failed and your order has not been placed.
              </p>
            ) : (
              <></>
            )}

            {/* {localStorage.getItem("AccessCode") == null ? (
              <p style={window.innerWidth > 775 ? listStyleY : listStyleY1}>
                Your purchase has been processed, if you have any queries please
                contact Monitor WA using the details above.
              </p>
            ) : (
              <></>
            )} */}
          </Grid>

          <Grid item xs={12} sm={12} md={3}></Grid>

          <Grid item xs={12} sm={12} md={3}></Grid>
          <Grid item xs={12} sm={12} md={6}>
            <p style={window.innerWidth > 775 ? listStyle : listStyle1}>
              For all your Smartrider accessories or other products provided by
              Monitor WA please visit our online store
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={3}></Grid>

          <Grid item xs={12} sm={12} md={4}></Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Button
              style={
                window.innerWidth > 775
                  ? {
                      backgroundColor: "green",
                      color: "#ffffff",
                      //marginLeft: '5%',
                      fontSize: 20,
                      width: "100%",
                    }
                  : {
                      backgroundColor: "green",
                      color: "#ffffff",
                      marginLeft: "25%",
                      fontSize: "110%",
                    }
              }
              onClick={onlineStore}
            >
              Monitor WA Online Store
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4}></Grid>
        </Grid>
        <Grid style={{ marginTop: "15%" }}></Grid>
      </div>
    </div>
  );
}

const styleHeader = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const styleHeader1 = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const listStyle = {
  fontSize: 20,
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const listStyle1 = {
  fontSize: "150%",
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const listStyleY = {
  fontSize: 20,
  color: "yellow",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const listStyleY1 = {
  fontSize: "150%",
  color: "yellow",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const listStyleY12 = {
  fontSize: "150%",
  color: "red",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const listStyleY2 = {
  fontSize: "150%",
  color: "red",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

export default PurchaseComplete;
